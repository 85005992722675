import React from 'react'
import { useCookies } from 'react-cookie'
import { Cookies } from 'react-cookie-consent'
import './CookieConsent.css'
import * as ReactGA from "react-ga"
import { useTranslation } from 'react-i18next'

function CookieConsent() {
    const {t} = useTranslation();
    const [cookies, setCookie] = useCookies(["cookieDecision", "cookieConsent"])
    const handleAcceptCookies = () => {
        setCookie("cookieDecision", true, {path: "/"})
        setCookie("cookieConsent", true, {path: "/"})
        // if (process.env.NODE_ENV === "production" || true) {
        //     ReactGA.initialize(`${process.env.COOKIES_KEY1}`);
        //     ReactGA.initialize(`${process.env.COOKIES_KEY2}`);
        // }
    }

    const handleDeclineCookies = () => {
        Cookies.remove("_ga", {path: '/'})
        Cookies.remove("_gat", {path: '/'})
        Cookies.remove("_gid", {path: '/'})
        setCookie("cookieDecision", true, {path: "/"})
        setCookie("cookieConsent", false, {path: "/"})
    }

    return (
        <div className='cookies-banner'>
            <p>{t('cookies_explain')}<a href='/politica-de-cookies' rel='nofollow'>{t('cookies_learn_more')}</a></p>
            <div className='cookies-button-group'>
                <button className='gray' onClick={handleDeclineCookies}>
                    {t('cookies_essentials')}
                </button>
                <button onClick={handleAcceptCookies}>
                    {t('cookies_accept')}
                </button>

            </div>
            
        </div>
    )
}

export default CookieConsent
